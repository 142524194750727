import cn from "classnames";
import { Field, Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as Yup from "yup";

import Button from "../../atoms/button/Button";
import { useLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Lead } from "../../features/leads-funnel/domain/lead";
import CatBellyUp from "../../images/organisms/health-questionnaire/catBellyUp.svg";
import CatNecklace from "../../images/organisms/health-questionnaire/catNecklace.svg";
import DogBall from "../../images/organisms/health-questionnaire/dogBall.svg";
import DogNecklace from "../../images/organisms/health-questionnaire/dogNecklace.svg";
import { Locale } from "../../settings/countries";
import { PetSpecies } from "../../settings/pet";
import { Events, track } from "../../utils/analytics";
import { experiments, isExperimentDefaultVariant } from "../../utils/experiments";
import {
  getCountryByLocale,
  shouldShowHowKnowUsQuestion,
} from "../../utils/locale-configuration-utils";
import { rollbar } from "../../utils/rollbar";
import PageStepForm from "../page-step-form/PageStepForm";
import { HealthQuestionnaire } from "./domain/health-questionnaire";
import * as styles from "./PreExistences.module.scss";
import {
  useHealthQuestionnaire,
  useSetHealthQuestionnaire,
} from "./use-cases/health-questionnaire-use-cases";

interface PreExistencesFormValues {
  preExistences: "no" | "yes";
}

const svgComponents = {
  [PetSpecies.cat]: {
    happyPet: <CatBellyUp />,
    necklacePet: <CatNecklace />,
  },
  [PetSpecies.dog]: {
    happyPet: <DogBall />,
    necklacePet: <DogNecklace />,
  },
};

const PreExistences = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const lead: Lead = useLead();
  const healthQuestionnaire: HealthQuestionnaire = useHealthQuestionnaire();
  const setHealthQuestionnaire = useSetHealthQuestionnaire();
  const { happyPet, necklacePet } = lead.petSpecies
    ? svgComponents[lead.petSpecies]
    : { happyPet: <DogBall />, necklacePet: <DogNecklace /> };

  const PreExistencesData = {
    initialValues: {
      preExistences: healthQuestionnaire.preExistences,
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      preExistences: Yup.string().trim().required(t("common.validation.required")),
    }),
    handleSubmit: (
      values: PreExistencesFormValues,
      { setSubmitting }: FormikHelpers<PreExistencesFormValues>
    ): void => {
      if (!setHealthQuestionnaire) {
        return;
      }

      setSubmitting(true);
      setHealthQuestionnaire({
        preExistences: values.preExistences,
      })
        .then((): void => {
          track(Events.FORM_ANSWERED, {
            healthQuestionnaire: {
              question: "Pre-existences",
              answer: values.preExistences,
            },
          });

          const nextUrl: string =
            values.preExistences === "yes"
              ? `/${i18n.language}/onboarding/health_questionnaire/diseases/`
              : shouldShowHowKnowUsQuestion(getCountryByLocale(i18n.language as Locale)) &&
                isExperimentDefaultVariant(experiments.howKnowUsQuestion)
              ? `/${i18n.language}/onboarding/how_know_us/`
              : `/${i18n.language}/onboarding/email/`;

          void navigate(nextUrl);
        })
        .catch((err): void => {
          rollbar.warn("There was an error setting health questionnnaire.", err, {
            fingerprint: "health-questionnaire-errors",
            context: "health-questionnaire-pre-existences",
          });
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<PreExistencesFormValues>) => {
      const { isSubmitting, isValid } = props;

      return (
        <Form className={cn(styles.preExistencesForm)}>
          <div className={cn(styles.happyPetSvgContainer)}>{happyPet}</div>
          <Field type="radio" name="preExistences" value="no" id="no" disabled={isSubmitting} />
          <label htmlFor="no" className={styles.happyPetLabel}>
            {t("health_questionnaire.pre_existences.answer.without_pre_existences")}
          </label>
          <div className={cn(styles.necklacePetSvgContainer)}>{necklacePet}</div>
          <Field type="radio" name="preExistences" value="yes" id="yes" disabled={isSubmitting} />
          <label htmlFor="yes" className={styles.necklacePetLabel}>
            {t("health_questionnaire.pre_existences.answer.with_pre_existences")}
          </label>
          <Button type="submit" disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm
      title={t("health_questionnaire.pre_existences.question", { petName: lead.petName })}
      subtitle={t("health_questionnaire.pre_existences.subtitle")}
      formData={PreExistencesData}
    />
  );
};

export default PreExistences;
